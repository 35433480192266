import React, { useState, useRef } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import emailjs from '@emailjs/browser';
import { motion } from "framer-motion"
import { useOnScreen } from "../hooks"

const Contact = () => {
    const query = useStaticQuery(graphql`
    {
      allContentful00HomePage {
        nodes {
          appointmentsInfo
          appointmentsTitle
        }
      }
    }
  `)
    const data = query.allContentful00HomePage.nodes[0];


    const form = useRef();
    const [buttonText, setButtonText] = useState("Schedule Appointment");

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm(process.env.SERVICE, process.env.TEMPLATE, form.current, process.env.USER)
            .then((result) => {
                console.log(result.text);
                setButtonText("Successful")
                setTimeout(() => {
                    setButtonText("Schedule Appointment")
                }, 2500);
            }, (error) => {
                console.log(error.text);
                setButtonText("Try again")
                setTimeout(() => {
                    setButtonText("Schedule Appointment")
                }, 2500);
            });
    };


    //  FRAMER ANIMATIONS
    const ref = useRef()
    const inView = useOnScreen(ref);

    return (
        <motion.section
            ref={ref}
            initial={{ opacity: 0 }}
            animate={{ opacity: inView ? 1 : 0 }}
            transition={{ type: "tween", duration: 0.8 }}
            className="contact"
            id="contact">
            <div className="container">
                <aside>
                    <h3>{data.appointmentsTitle}</h3>
                    <div className="underline"></div>
                    <p>{data.appointmentsInfo}</p>
                </aside>
                <form ref={form} onSubmit={sendEmail}>
                    <div className="row">
                        <div className="col-12">
                            <label htmlFor="name">Full Name</label>
                            <input
                                required
                                type="text"
                                name="name"
                                placeholder="Jack Robbin" />
                        </div>

                        <div className="col-12 col-sm-6">
                            <label htmlFor="email">E-mail</label>
                            <input
                                required
                                type="text"
                                name="email"
                                placeholder="name@domian.com" />
                        </div>
                        <div className="col-12 col-sm-6">
                            <label htmlFor="number">Phone Number</label>
                            <input
                                required
                                type="text"
                                name="number"
                                placeholder="+234 ___ ___ ____" />
                        </div>

                        <div className="col-12 col-sm-6">
                            <label htmlFor="date">Preferred Date</label>
                            <input
                                required
                                type="date"
                                name="date" />
                        </div>
                        <div className="col-12 col-sm-6">
                            <label htmlFor="time">Preferred Time</label>
                            <input
                                required
                                type="time"
                                name="time" />
                        </div>

                        <div className="col-12">
                            <label htmlFor="message">Message</label>
                            <textarea
                                required
                                type="text"
                                name="message"
                                placeholder="Have something to tell us, type here..."
                                rows="4" />
                        </div>

                        <button
                            className="btn-main"
                            type="submit">{buttonText}</button>
                    </div>
                </form>
            </div>
        </motion.section>
    )
}

export default Contact

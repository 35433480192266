import * as React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import Header from "../components/Header";
import Details from "../components/Details";
import Hero from "../components/Hero";
import Service from "../components/Service";
import Footer from "../components/Footer";
import About from "../components/About";
import Team from "../components/Team";
import Contact from "../components/Contact";

const IndexPage = () => {
  const query = useStaticQuery(graphql`
    {
      allContentful00Seo {
        nodes {
          author
          description
          keywords
          robots
          url
          title
        }
      }
    }
  `);

  const data = query.allContentful00Seo.nodes[0];

  return (
    <main>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <meta charSet="utf-8" />
        <meta name="description" content={data.description} />
        <meta name="keywords" content={data.keywords} />
        <meta name="robots" content={data.robots} />
        <meta property="og:title" content={data.title} />
        <meta property="og:description" content={data.description} />
        <meta property="og:url" content={data.url} />
        {/* <meta property="og:image" content="Segun Owoeye LP" /> */}
        <meta name="author" content={data.author} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>{data.title}</title>
      </Helmet>
      <Details />
      <Header />
      <Hero />
      <About />
      <Service />
      <Team />
      <Contact />
      <Footer />
    </main>
  );
};

export default IndexPage;
